import CardList from "../components/Card/CardList";
import img_01 from "../components/Card/img/card-img-01.jpg";
import React from "react";
import TagsWrapp from "../components/Tags/TagsWrapp";
import SearchResultBar from "../components/SearchResultBar/SearchResultBar";

const productsList = [
    {
        title: "Ribbed polo-Neck Jumper",
        brand: "Jack & James",
        price: "$39.90",
        description: "Straight cut shirt jacket in sturdy, washed denim. Features a pointed collar and buttons down the front.",
        img: img_01
    },
    {
        title: "Ribbed polo-Neck Jumper",
        brand: "Jack & James",
        price: "$39.90",
        description: "Straight cut shirt jacket in sturdy, washed denim. Features a pointed collar and buttons down the front.",
        img: img_01
    },
    {
        title: "Ribbed polo-Neck Jumper",
        brand: "Jack & James",
        price: "$39.90",
        description: "Straight cut shirt jacket in sturdy, washed denim. Features a pointed collar and buttons down the front.",
        img: img_01
    },
];
const ProductsListPage = () => {
    return (
        <div>
            <TagsWrapp />
            <SearchResultBar/>
            <CardList
                productsList={productsList}
            />
        </div>
    );
}

export default ProductsListPage;
