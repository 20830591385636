import React from 'react';
import './Tags.sass';
import Search from "../Search/Search";
import TagItem from "./TagItem";

const tagsContent = [
    { title: "one" },
    { title: "two" },
    { title: "three" },
    { title: "one" },
    { title: "two" },
    { title: "three" },
    { title: "one" },
    { title: "two" },
    { title: "three" },
    { title: "one" },
    { title: "two" },
];
const tagsContentSecond = [
    { title: "oneded" },
    { title: "twdededededeo" },
    { title: "tdedhree" },
    { title: "one" },
    { title: "twsferfeo" },
    { title: "three" },
    { title: "one" },
    { title: "twoeeeeeffefsefe" },
    { title: "three" },
    { title: "onfesfee" },
    { title: "two" },
    { title: "one" },
    { title: "two" },
    { title: "three" },
    { title: "oefne" },
    { title: "two" },
    { title: "three" },
    { title: "one" },
    { title: "two" },
    { title: "three" },
    { title: "one" },
    { title: "two" },
];

const TagsWrapp = () => {
    return (
        <section className="tags">
            <div className="container">
                <div className="tags__wrapp">
                    <div className="tags__list">
                        {tagsContent.map((item, index) => (
                            <TagItem
                                key={index}
                                title={item.title}
                                isActive={index === 0}
                            />
                        ))}
                    </div>
                    <Search/>
                </div>
                <div className="tags__list width-full">
                    {tagsContentSecond.map((item, index) => (
                        <TagItem
                            key={index}
                            title={item.title}
                            isActive={index === 5}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default TagsWrapp;
