import React from "react";
import Special from "../components/Special/Special";
import ProductItem from "../components/ProductItem/ProductItem";

const ProductPage = () => {
    return (
        <div>
            <ProductItem />
            <Special/>
        </div>
    );
}

export default ProductPage;
