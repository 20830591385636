import React from 'react';
import './SpecialItem.sass';

const SpecialItem = ({ image, title, subtitle, price, oldPrice }) => {
    return (
        <a href="/product-page" className="special-item">
            <img className="special-item__img" src={image} alt=""/>
            <h3 className="special-item__title">
                {title}
            </h3>
            <p className="special-item__subtitle">
                {subtitle}
            </p>
            <div className="special-item__price-wrapp">
                <p className="special-item__price">
                    {price}
                </p>
                <p className="special-item__price old">
                    {oldPrice}
                </p>
            </div>
        </a>
    );
}

export default SpecialItem;
