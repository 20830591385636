import './Card.sass';
import CardItem from "./CardItem";

import img_01 from './img/card-img-01.jpg';
import img_02 from './img/card-img-02.jpg';
import img_03 from './img/card-img-03.jpg';
import img_04 from './img/card-img-04.jpg';

const products = [
    {
        title: "Ribbed polo-Neck Jumper",
        brand: "Jack & James",
        price: "$39.90",
        price_old: "$59.90",
        description: "Straight cut shirt jacket in sturdy, washed denim. Features a pointed collar and buttons down the front.",
        img: img_01
    },
    {
        title: "Polo-Neck Jumper",
        brand: "Jack & James",
        price: "$39.90",
        price_old: "$59.90",
        description: "Straight cut shirt jacket in sturdy, washed denim. Features a pointed collar and buttons down the front.",
        img: img_02
    },
    {
        title: "Ribbed Jumper",
        brand: "Jack & James",
        price: "$39.90",
        price_old: "$59.90",
        description: "Straight cut shirt jacket in sturdy, washed denim. Features a pointed collar and buttons down the front.",
        img: img_03
    },
    {
        title: "Jumper",
        brand: "Jack & James",
        price: "$39.90",
        price_old: "$59.90",
        description: "Straight cut shirt jacket in sturdy, washed denim. Features a pointed collar and buttons down the front.",
        img: img_04
    },
];

const CardList = () => {
    return (
        <section className="products">
            <div className="container">
                <div className="card__list">
                    {products.map((product, index) => (
                        <CardItem
                            key={index}
                            image={product.img}
                            title={product.title}
                            brand={product.brand}
                            price={product.price}
                            priceOld={product.price_old}
                            description={product.description}
                        />
                    ))}
                </div>
                <div className="card__more">
                    <button className="card__more-btn">
                        Load more
                    </button>
                </div>
            </div>
        </section>
    );
}

export default CardList;
