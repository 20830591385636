import '../SearchResultBar/SearchResultBar.sass';

const resultText = "Weed";
const resultQuantity = "15";

const SearchResultBar = () => {
    return (
        <section className="search-result-bar__inner">
            <div className="container">
                <div className="search-result-bar">
                    <h3 className="search-result-bar__title">
                        search results for "<b>{resultText}</b>"
                    </h3>
                    <p className="search-result-bar__quantity">
                        "<b>{resultQuantity}</b>" results
                    </p>
                </div>
            </div>
        </section>
    );
}

export default SearchResultBar;
