import Categories from "../components/Categories/Categories";
import Special from "../components/Special/Special";
import Newsletter from '../components/Newsletter/Newsletter';

const HomePage = () => {
    return (
        <div>
            <Categories/>
            <Special/>
            <Newsletter/>
        </div>
    );
}

export default HomePage;
