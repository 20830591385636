import React from 'react';
import './CategoriesItem.sass';


const CategoriesItem = ({ btnText, image }) => {
    return (
        <div className="categories-item">
            <img src={ image } alt="" className="categories-item__img"/>
            <a href="/products" className="categories-item__btn">{ btnText }</a>
        </div>
    );
}

export default CategoriesItem;
