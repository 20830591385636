import React, {useState} from 'react';
import './sass/main.sass'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import HomePage from "./pages/HomePage";
import ProductsListPage from "./pages/ProductsListPage";
import ProductPage from "./pages/ProductPage";
import ProfilePage from "./pages/ProfilePage";
import TextSlider from "./components/TextSlider/TextSlider";
import CartPage from "./pages/CartPage";
import AgePopup from "./components/Popups/AgePopup";

function App() {
    const [isAgePopupOpen, setIsAgePopupOpen] = useState(true);

    const handleCloseAgePopup = () => {
        setIsAgePopupOpen(false);
    };
    return (
        <Router>
            <div className="App" >
                <Header />
                <TextSlider/>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/products" element={<ProductsListPage />} />
                    <Route path="/product-page" element={<ProductPage />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/cart" element={<CartPage />} />
                </Routes>
                <Footer />
                {isAgePopupOpen && <AgePopup onClose={handleCloseAgePopup} />}
            </div>
        </Router>
    );
}

export default App;
