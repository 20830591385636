import React from 'react';
import './Tags.sass';

const TagItem = ({title, isActive}) => {
    return (
        <div className={`tags__item ${isActive ? 'active' : ''}`} key={0}>
            <h3 className="tags__item-title">
                {title}
            </h3>
        </div>
    );
}

export default TagItem;
